import _ from 'lodash'

let watermark = {}

let setWatermark = (str, opacity, theme) => {
  let id = 'watermark610227'
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }

  //创建一个画布
  let can = document.createElement('canvas')
  //设置画布的长宽
  can.width = 281
  can.height = 281

  let cans = can.getContext('2d')

  cans.translate(can.width / 2, can.height / 2)
  //旋转角度
  cans.rotate((-15 * Math.PI) / 180)
  cans.font = '14px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
  //设置填充绘画的颜色、渐变或者模式
  cans.fillStyle = theme == 1 ? `rgba(255, 255, 255, 0.${opacity})` : `rgba(0, 0, 0, 0.${opacity})`
  //设置文本内容的当前对齐方式
  cans.textAlign = 'center'
  //设置在绘制文本时使用的当前文本基线
  cans.textBaseline = 'Middle'
  //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
  cans.fillText(str, 0, 0)

  let div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '30px'
  div.style.left = '0px'
  div.style.position = 'fixed'
  div.style.zIndex = '1000'
  div.style.width = document.documentElement.clientWidth + 'px'
  div.style.height = document.documentElement.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  document.body.appendChild(div)
  return id
}

// 该方法只允许调用一次
watermark.set = (str, opacity = 12, theme = 0) => {
  let id = setWatermark(str, opacity, theme)
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str, opacity, theme)
    }
  }, 1000)
  window.addEventListener(
    'resize',
    () => {
      debounce()
    },
    false
  )
  const debounce = _.debounce(
    () => {
      watermark.set(str, opacity, theme)
    },
    300,
    {
      trailing: true,
    }
  )
}

export default watermark
