<template>
  <router-view v-if="loadLayout" />
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import watermark from '@/plugin/watermark.js'
import { getHomeInfo, getdashboardmaintInfo, getOrCreateProject, getCityWeather, getFestivals, getProjectMalls } from '@/api/apiV2_dashboard'
import BI_config from '@/utils/config'
import { postAlarm } from '@/api/otherapi'

export default {
  name: 'appinner',
  data() {
    return {
      info: null,
      menus: {},
      loadLayout: false,
      isMobile: false,
      system: 'bi',
    }
  },
  components: {},
  computed: {
    ...mapState({
      defaultMenus: state => state.defaultMenus,
      basicInfo: state => state.basicInfo,
    }),
  },
  watch: {
    'basicInfo.project.disableWatermark': function() {
      if (!this.basicInfo.project.disableWatermark) {
        watermark.set(this.basicInfo.user.name, this.basicInfo.project.watermarkOpacity, this.basicInfo.project.theme)
      } else {
        watermark.set('')
      }
    },
    'basicInfo.project.watermarkOpacity': function() {
      if (!this.basicInfo.project.disableWatermark) {
        watermark.set(this.basicInfo.user.name, this.basicInfo.project.watermarkOpacity, this.basicInfo.project.theme)
      } else {
        watermark.set('')
      }
    },
  },
  async created() {
    // 如果手机端访问pc端链接 自动跳转到手机端
    if (this.$commonJs2._isMobile()) {
      if (!this.$route.meta.clienttype) {
        if (this.$route.fullPath.includes('/dashboard/list')) {
          this.$router.push({ path: '/v2/dashboard/MobileList' })
        } else if (this.$route.fullPath.includes('/dashboard/Index/')) {
          const newpath = this.$route.fullPath.replace('Index', 'Mobile')
          this.$router.push({ path: newpath })
        }
      }
    } else {
      if (this.$route.meta.clienttype) {
        if (this.$route.fullPath.includes('/dashboard/MobileList')) {
          this.$router.push({ path: '/v2/dashboard/list' })
        } else if (this.$route.fullPath.includes('/dashboard/Mobile/')) {
          const newpath = this.$route.fullPath.replace('Mobile', 'Index')
          this.$router.push({ path: newpath })
        }
      }
    }

    let systems = ['bi', 'sale', 'me', 'crm']
    if (systems.includes(this.$route.name)) {
      this.system = this.$route.name
    }
    this.$store.commit('setSystem', this.system)
    let projectID = await this.getProjectID()
    this.$store.commit('setProjectID', projectID)
    if (!projectID) {
      this.loadLayout = true
    } else {
      //获取天和节假日
      this.getWeatherAndFestivalData()
      if (this.system === 'bi') {
        await this.$commonJs._salejudge(this.$route)

        setTimeout(() => {
          this.$set(this.menus, 'dashboards', this.defaultMenus && this.defaultMenus.dashboards)
          this.$set(this.menus, 'customMenus', this.defaultMenus && this.defaultMenus.customMenus)
          this.$set(this.menus, 'defaultMenus', this.defaultMenus && this.defaultMenus.defaultMenus)
          if (this.menus.dashboards && this.menus.defaultMenus) {
            this.$store.commit('setMenus', this.menus)
          }
          if (this.menus.defaultMenus && this.menus.defaultMenus.length === 0) {
            //权限 没菜单权限跳转404
            this.$router.push({ path: `${BI_config.dashboardProxy}/404` })
          }
          this.isloadOK()
        }, 10)
      }
      getProjectMalls().then(v => {
        this.$store.commit('setProjectMalls', v.data)
      })
      if (this.system == 'bi') {
        getdashboardmaintInfo().then(v => {
          this.$store.commit('setmaintInfo', v.data)
        })
      }
      getHomeInfo().then(v => {
        this.info = v.data
        let radioUnit = v.data && v.data.project.axisUnit
        this.$store.commit('setbasicInfo', v.data)
        this.$store.commit('setPageunit', radioUnit)
        this.$pageunit = radioUnit

        let themeColor = v.data && v.data.project.theme > 0 ? 'dark' : 'light'
        let mainColor = 'lan'
        switch (v.data && v.data.project.mainColor) {
          case 0:
            mainColor = 'lan'
            break
          case 1:
            mainColor = 'cheng'
            break
          case 2:
            mainColor = 'qing'
            break
          case 3:
            mainColor = 'hong'
            break
          case 4:
            mainColor = 'zi'
            break
        }
        window.document.documentElement.setAttribute('data-theme', themeColor)
        window.document.documentElement.setAttribute('data-high', mainColor)

        // 默认 开启水印
        if (v && !(v.data && v.data.project.disableWatermark)) {
          watermark.set(v.data.user.name, 12, v.data.project.theme)
        }

        this.isloadOK()
      })
    }
  },
  methods: {
    isloadOK() {
      // 接口全部请求完成再跳转  因为跳转的时候会把没有请求完成的接口都取消
      if (['crm', 'me', 'sale'].includes(this.system)) {
        this.loadLayout = true
      } else {
        if (this.menus && this.menus.dashboards && this.menus.defaultMenus && this.info) {
          let defaultMenus = this.menus.defaultMenus
          //如果没有数据模板的访问权限，跳转到分析模型
          const dashboard = defaultMenus.find(x => {
            return x.code == 'dashboard' && x.maintValidate.m == 1
          })
          if (!dashboard) {
            const analysis = defaultMenus.find(x => {
              return x.code == 'analysis' && x.maintValidate.m == 1
            })
            if (analysis && analysis.children && analysis.children.length > 0) {
              window.location.href = analysis.href
            } else {
              const system = defaultMenus.find(x => {
                return x.code == 'system' && x.maintValidate.m == 1
              })
              if (system && system.children && system.children.length > 0) {
                window.location.href = system.href
              }
            }
            const ppt = defaultMenus.find(x => {
              return x.code == 'ppt' && x.maintValidate.m == 1
            })
            if (!analysis && ppt) {
              window.location.href = ppt.href
            }
            return
          }
          if (!this.$route.hash) {
            // const hash = dashboard.href ? dashboard.href.split('#/')[1] : ''
            const dashboardID = this.$route.query.dashboardID || (dashboard.href ? dashboard.href.split('#/')[1] : '')
            this.$router.replace({ path: this.$route.path + '#/' + dashboardID })
            // this.$router.replace({ path: this.$route.path, query: { dashboardID } })
          }
          this.loadLayout = true
        }
      }
    },
    toFirstDashboardHref() {},

    async getProjectID() {
      if (['me', 'sale'].includes(this.system)) {
        //根据mallID 获取项目ID
        let mallID = this.$route.params.mallID
        let res = await getOrCreateProject(mallID)
        return res.data && res.data.id
      }
      return this.$route.params.projectID
    },

    getWeatherAndFestivalData() {
      getCityWeather().then(res => {
        let weathers = {
          loading: false,
          data: [],
        }
        let set = new Set()
        res &&
          res.data.forEach(item => {
            if (!set.has(item.d)) {
              var icon = item.s.split('/')[0]
              var temperatures = item.t.split('/')
              weathers.data.push([item.d, icon, temperatures[0], temperatures[1], item.w.split('/')[0]])
              set.add(item.d)
            }
          })
        this.$store.commit('setWeathers', weathers)
      })
      getFestivals().then(res => {
        let festivals = {
          loading: false,
          statutory: res.data && res.data.statutoryFestivals,
          custom: res.data && res.data.customFestivals,
        }
        this.$store.commit('setFestivals', festivals)
      })
    },
  },
  mounted() {
    Vue.config.errorHandler = (err, vm, info) => {
      console.error(err)
      //测试环境不告警
      if (window.location.href.includes('-t')) return
      //没必要的js报错排除钉钉警告
      if (
        err.message &&
        (err.message.includes('路由跳转取消请求') ||
          err.message.includes('Cannot read properties of null') ||
          err.message.includes("Cannot read property 'level' of null") ||
          err.message.includes('ResizeObserver loop limit exceeded') ||
          err.message.includes('Request failed with status code 400') ||
          err.message.includes('Request failed with status code 401'))
      ) {
        return
      }

      if (process.env.NODE_ENV === 'production') {
        const param = {
          message: err.message,
          stackTrace: err.stack,
          referer: window.location.href,
        }
        // 钉钉告警
        postAlarm(param)
        // sgm 告警
        window.__sgm__.error(err)
      }
    }
  },
}
</script>
