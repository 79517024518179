import request from '@/utils/request2'

//警告
export function postAlarm(data) {
  return request({
    url: '/alarm',
    method: 'post',
    data: data,
  })
}
//页面水印配置
export function putWatermark(data) {
  return request({
    url: '/project/watermark',
    method: 'put',
    data: data,
  })
}